<template>
  <section class="user-streamline main-body">
    <h3 class="subheading">User Info</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="sso-id">SSO ID</label>
          <v-text-field
            id="sso-id"
            v-model="userData.id"
            solo
            flat
            @change="status = 'needsSaving'"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.PreventEntitlementEmails">
        <v-flex mr-5 class="half">
          <label class="label" for="email">Email</label>
          <v-text-field
            id="email"
            v-model="userData.attributes.userEmail"
            solo
            flat
            @change="status = 'needsSaving'"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i></i></p>
        </v-flex>
      </v-layout>
    </section>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Create User'" @buttonClicked="createEntitlement()" />
  </section>
</template>

<script>
import axios from "axios"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "UserCreateStreamline",

  components: {
    StickyFooter
  },

  created() {
    this.modules = require("../../../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  },

  data:() => ({
    api: `${process.env.VUE_APP_STREAMLINEAPI}/users`,
    userData: {
      type: "streamline-user",
      id: "",
      attributes: {
        userEmail: "",
      }
    },
    status: "saved",
    errorMessage: "",
    noUser: false,
    modules: null
  }),

  methods: {
    createEntitlement() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PUT",
          url: `${this.api}`,
          headers: { "Authorization": jwt },
          data: {
            data: this.userData
          }
        })
        .then(response => {
          console.log("Created streamline user:", response);

          this.$router.push({
            path: `/users/edit/${response.data.data.id}/`
          })

          this.$store.commit("completeLoading");
          this.status = "saved";
        })
        .catch(e => {
          console.error("Problem creating streamline user", e);

          this.$store.commit("completeLoading");
          this.status = "needsSaving";
          this.errorMessage = "Problem creating streamline user";
        });
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .rawOutput {
    font-size: 9pt;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
  }
</style>
